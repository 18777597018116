import type { SupabaseClient } from "@supabase/supabase-js";
import { createContext, useContext, useEffect, useState } from "react";
import { createOrGetSupabaseClient } from "./supabaseClient";
type SupabaseContextProps = { supabaseClient: SupabaseClient } | undefined;

export const SupbabaseClientContext =
  createContext<SupabaseContextProps>(undefined);

export const SupabaseClientProvider = ({
  children,
  localDevSupabaseAuthUrl,
}: {
  children: React.ReactNode;
  localDevSupabaseAuthUrl: string;
}) => {
  const [supabaseClient] = useState(() =>
    createOrGetSupabaseClient(localDevSupabaseAuthUrl)
  );

  useEffect(() => {
    return () => {
      if (supabaseClient) {
        console.log("closing GoTrue client");
        supabaseClient.removeAllChannels();
        supabaseClient.auth.stopAutoRefresh();
      }
    };
  }, [supabaseClient]);

  return (
    <SupbabaseClientContext.Provider value={{ supabaseClient }}>
      {children}
    </SupbabaseClientContext.Provider>
  );
};

export const useSupabaseClient = () => {
  const context = useContext(SupbabaseClientContext);
  if (context === undefined) {
    throw new Error(
      "useSupabaseClient must be used within a SupabaseClientProvider"
    );
  }
  return context;
};

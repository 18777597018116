import { createBrowserClient } from "@supabase/auth-helpers-remix";
import { environmentVariable } from "~/environmentVariable";

export const createOrGetSupabaseClient = (localDevSupabaseAuthUrl: string) =>
  createBrowserClient(
    environmentVariable("PUBLIC_SUPABASE_URL")!,
    environmentVariable("PUBLIC_SUPABASE_ANON_KEY")!,
    {
      options: {
        global:
          environmentVariable("PUBLIC_USE_SUPABASE_DEV_PROXY") === "true"
            ? {
                fetch: (url, options) => {
                  if (url.toString().startsWith("http://localhost:54321/")) {
                    const proxiedUrl = url
                      .toString()
                      .replace(
                        "http://localhost:54321",
                        localDevSupabaseAuthUrl
                      );
                    return fetch(proxiedUrl, {
                      ...options,
                    });
                  }
                  return fetch(url.toString(), { ...options });
                },
              }
            : {},
      },
    }
  );
